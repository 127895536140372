var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          style: _vm.height,
          attrs: {
            "label-width": "110px",
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "username", label: "用户名" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: _vm.isDetail,
                  size: "mini",
                  clearable: "",
                  placeholder: "请输入用户名",
                },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _c("biz-edit", {
            ref: "bizEdit",
            attrs: {
              "is-admin": _vm.isAdmin,
              "is-detail": _vm.isDetail,
              "is-add": _vm.isAdd || _vm.isBase,
              props: ["tenantId", "businessId", "upperBizId"],
              "data-obj": _vm.form,
            },
            on: { "select-company": _vm.onSelectCompany },
          }),
          _c(
            "el-form-item",
            { attrs: { prop: "password", label: "用户密码" } },
            [
              !_vm.isAdd && !_vm.isDetail
                ? _c("div", { staticClass: "edit-pwd-box" }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            clearable: "",
                            type: "password",
                            placeholder: _vm.pwdPlaceHolder,
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-edit",
                              size: "mini",
                              type: "primary",
                            },
                            on: { click: _vm.onEditPassword },
                          },
                          [_vm._v("修改密码")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: {
                          readOnly: _vm.isDetail,
                          size: "mini",
                          clearable: "",
                          type: "password",
                          placeholder: _vm.pwdPlaceHolder,
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
            ]
          ),
          _vm.isAdd
            ? _c(
                "el-form-item",
                { attrs: { prop: "password2", label: "二次确认密码" } },
                [
                  _c("el-input", {
                    attrs: {
                      readOnly: _vm.isDetail,
                      size: "mini",
                      clearable: "",
                      type: "password",
                      placeholder: "请输入确认密码",
                    },
                    model: {
                      value: _vm.form.password2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password2", $$v)
                      },
                      expression: "form.password2",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "mobile", label: "联系方式" } },
            [
              _c("el-input", {
                attrs: {
                  readOnly: _vm.isDetail,
                  size: "mini",
                  clearable: "",
                  placeholder: "请输入 11 位的手机号",
                  maxlength: "11",
                },
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile", $$v)
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户权限" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDetail,
                  "active-text": "正常",
                  "inactive-text": "只读",
                  "active-value": 0,
                  "inactive-value": 1,
                },
                model: {
                  value: _vm.form.roleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleId", $$v)
                  },
                  expression: "form.roleId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "独立用户" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c("span", [_vm._v("独立用户")]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { placement: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: {
                          "font-size": "16px",
                          "vertical-align": "middle",
                        },
                      }),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            "独立用户将只能看到自己及自己名下所属的公司、设备，将无法看到哪怕是同公司的其他人的任何信息"
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDetail,
                  "active-text": "是",
                  "inactive-text": "否",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                on: { change: _vm.onSlelectForceUpdate },
                model: {
                  value: _vm.form.self,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "self", $$v)
                  },
                  expression: "form.self",
                },
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "不可删除" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDetail,
                  "active-text": "是",
                  "inactive-text": "否",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                on: { change: _vm.onSlelectForceUpdate },
                model: {
                  value: _vm.form.delFlg,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "delFlg", $$v)
                  },
                  expression: "form.delFlg",
                },
              }),
            ],
            1
          ),
          _vm.user.tenantId === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "CAN 编辑权限" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.isDetail,
                      "active-text": "是",
                      "inactive-text": "否",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    on: { change: _vm.onSlelectForceUpdate },
                    model: {
                      value: _vm.form.canEditCanConfig,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "canEditCanConfig", $$v)
                      },
                      expression: "form.canEditCanConfig",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isDetail
        ? _c(
            "div",
            [
              _c("foot-btns", {
                on: { cancel: _vm.onCancel, submit: _vm.onSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }