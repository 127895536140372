import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`user/queryByPage?${rand}`, { params });
}
// 增加
export const add = (data) => {
    const rand = Date.now();
    return request.post(`user/user?${rand}`, data);
}
export const addWithoutToken = (data) => {
    const rand = Date.now();
    return request.post(`user/add?${rand}`, data);
}
// 编辑
export const edit = (data) => {
    const rand = Date.now();
    return request.post(`user/update/?${rand}`, data);
}
// 删除
export const del = (id) => {
    const rand = Date.now();
    return request.get(`user/delete/${id}?${rand}`);
}
// 详情
export const detail = (id) => {
    const rand = Date.now();
    return request.get(`user/${id}/?${rand}`);
}

export const listDetail = async (id) => {
    const params = { id },
    rand = Date.now();
    const res = await request.get(`user/listt?${rand}`, { params });
    if (!res || !res.data) return null;
    return res.data[0];
}

// 重置密码
export const resetting = (data) => {
    const rand = Date.now();
    return request.post(`/user/resetting?${rand}`, data);
}

export default {
    list,
    add,
    addWithoutToken,
    edit,
    del,
    detail,
    listDetail,
    resetting
}