<template>
  <div v-loading="loading" style="height: 100%;">
    <el-form label-width="110px" :rules="rules" ref="form" :model="form" label-position="left" :style="height">
      <el-form-item prop="username" label="用户名">
        <el-input :readonly="isDetail" size="mini" clearable v-model="form.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <biz-edit :is-admin="isAdmin" ref="bizEdit" :is-detail="isDetail" :is-add="isAdd || isBase"
        :props="['tenantId', 'businessId', 'upperBizId']"
        :data-obj="form" @select-company="onSelectCompany" />
      <el-form-item prop="password" label="用户密码">
        <div v-if="!isAdd && !isDetail" class="edit-pwd-box">
          <div>
            <el-input size="mini" clearable type="password" v-model="form.password" :placeholder="pwdPlaceHolder"></el-input>
          </div>
          <div>
            <el-button icon="el-icon-edit" size="mini" type="primary" @click="onEditPassword">修改密码</el-button>
          </div>
        </div>
        <div v-else>
          <el-input :readOnly="isDetail" size="mini" clearable type="password" v-model="form.password" :placeholder="pwdPlaceHolder"></el-input>
        </div>
      </el-form-item>
      <el-form-item v-if="isAdd" prop="password2" label="二次确认密码">
        <el-input :readOnly="isDetail" size="mini" clearable type="password" v-model="form.password2" placeholder="请输入确认密码"></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="联系方式">
        <el-input :readOnly="isDetail" size="mini" clearable placeholder="请输入 11 位的手机号" maxlength="11" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="用户权限">
        <el-switch
          :disabled="isDetail"
          v-model="form.roleId"
          active-text="正常"
          inactive-text="只读"
          :active-value="0"
          :inactive-value="1"
          >
        </el-switch>
      </el-form-item>
      <el-form-item label="独立用户">
        <template slot="label">
          <span>独立用户</span>
          <el-tooltip class="item" placement="right" style="margin-left: 5px;">
            <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
            <div slot="content">独立用户将只能看到自己及自己名下所属的公司、设备，将无法看到哪怕是同公司的其他人的任何信息</div>
          </el-tooltip>
        </template>
        <el-switch
          :disabled="isDetail"
          v-model="form.self"
          active-text="是"
          inactive-text="否"
          :active-value="1"
          :inactive-value="0"
          @change="onSlelectForceUpdate"
          >
        </el-switch>
      </el-form-item>
      <el-form-item label="不可删除">
        <el-switch
          :disabled="isDetail"
          v-model="form.delFlg"
          active-text="是"
          inactive-text="否"
          :active-value="1"
          :inactive-value="0"
          @change="onSlelectForceUpdate"
          >
        </el-switch>
      </el-form-item>
      <el-form-item label="CAN 编辑权限" v-if="user.tenantId === 0">
        <el-switch
          :disabled="isDetail"
          v-model="form.canEditCanConfig"
          active-text="是"
          inactive-text="否"
          :active-value="1"
          :inactive-value="0"
          @change="onSlelectForceUpdate"
          >
        </el-switch>
      </el-form-item>
    </el-form>
    <div v-if="!isDetail">
      <foot-btns @cancel="onCancel" @submit="onSubmit" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import userApi from "@/api/user";

import BizEdit from "@/components/business/edit";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    BizEdit
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isBase: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      pwdPlaceHolder: "",
      isEditPassword: false,
      isAdmin: false,
      height: "",
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        tenantId: [
          { required: true, message: '请选择一个公司分类', trigger: 'change' }
        ],
        businessId: [
          { required: true, message: '请选择一个公司', trigger: 'change' }
        ],
        password: [
          { required: this.isAdd || this.isEditPassword, message: '请输入密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {

              if (!value) {
                const val = this.isEditPassword ? "请输入修改密码" : undefined;
                callback(val);
                return;
              }
              if (value.length < 6) {
                callback("密码长度不能小于六位");
                return;
              }
              let code = 0;
              if (/\d/.test(value)) {
                code++;
              }
              if (/[a-zA-Z]/.test(value)) {
                code++;
              }
              if (code < 2) {
                callback("密码中至少要包含数字和字母");
                return;
              }

              callback();
            }
          }
        ],
        password2: [
          { required: true, message: '请输入二次密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback();
                return;
              }
              if (this.form.password !== value) {
                callback("输入的密码和二次确认密码不一致");
                return;
              }
              callback();
            }
          }
        ],
        mobile: [
          // { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback();
                return;
              }
              if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
              }
              callback();
            }
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { required: true, type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ]
      },
      form: {
        username: "",
        tenantId: "",
        businessId: "",
        password: "",
        password2: "",
        mobile: "",
        staffCode: "",
        status: 0,
        roleId: 0,
        self: 0,
        delFlg: 0,
        canEditCanConfig: 0
      },
      backForm: {},
      company: {},
    }
  },
  watch: {
    isDetail: function () {
      if (this.data !== null) {
        this.getDatas();
      }
      this.calcHeight();
    },
    data: function() {
      if (!this.isAdd) {
        this.getDatas();
      }
    }
  },
  async created () {
    console.log("user-base");
    this.isAdmin = this.user.tenantId === 0;
    this.pwdPlaceHolder = this.isAdd ? "请输入密码" : "旧密码不可查询，只能修改，留空表示不修改密码";

    if (this.isAdd) {
      this.getDatas();
    }
  },
  mounted () {
    this.calcHeight();
  },
  methods: {
    calcHeight () {
      this.height = this.isDetail ? "height: calc(100% - 40px);" : "height: calc(100% - 82px);"
    },
    getDatas () {
      let tmp = null;
      if (!this.isAdd) {
        const grade = Number(this.data.grade);

        tmp = Object.assign({
          tenantId: this.user.tenantId,
          companyName: this.user.businessName,
          canEditCanConfig: (grade & 32) >> 5,
          self: (grade & 16) >> 4,
          delFlg: (grade & 8) >> 3
        }, this.data);
        tmp.businessId = Number(tmp.businessId);

        this.$emit("set-del-flag", this.form.delFlg === 1);
      } else {
        tmp = Object.assign({}, this.form);
        if (!this.isAdmin) {
          tmp.tenantId = this.user.tenantId;
          tmp.businessId = this.user.businessId;
        }
      }

      tmp.upperBizId = this.user.businessId;

      this.form = tmp;
      this.backForm = Object.assign({}, tmp);
    },
    onSlelectForceUpdate (val) {
      this.$forceUpdate();
    },
    onClose () {
      if (this.isAdd) {
        this.$router.push({
          path: "/user"
        });
      } else {
        this.$emit("close", this.form);
      }
    },
    onCancel () {
      const title = this.isAdd ? "添加帐号" : `编辑当前用户（${this.backForm.username}）`;
      $utils.cancelConfirm(this, title, () => {
        this.form = Object.assign({}, this.backForm);
        this.onClose();
      });
    },
    onEditPassword () {
      this.isEditPassword = true;
      this.$refs.form.validateField("password", async (error) => {
        if (!error) {
          try {
            const submitData = Object.assign({}, this.form);
            await userApi.resetting(submitData);

            this.$message.success(`密码修改成功`);
          } catch {
            this.$message.warning(`密码修改失败`);
          } finally {
            this.isEditPassword = false;
          }
        }
      });
    },
    onSubmit () {
      this.isEditPassword = false;
      this.$refs.form.validate(async valid => {

        if (valid) {

          const company = this.company;
          const submitData = Object.assign(this.form, {
            tenantId: company.tenantId,
            companyName: company.companyName, // 这里是为了防止出错，所以覆盖一下
            staffCode: company.subsidiaryId // 直接用所选择的 公司 subsidiaryId 作为用户的 staffcode
          });

          if (!this.isAdd) {
            delete submitData.password;
          }

          const message = this.isAdd ? "添加" : "修改";
          try {
            this.loading = true;

            let staffCode = company.subsidiaryId.split(",");
            staffCode.shift(); // 去除首尾的空白
            staffCode.pop();

            if (this.isAdd) {
              delete submitData.password2;
            }

            /**
            * grade 12345 各个位阶安排
            * 第一位：CAN 编辑权限
            * 第二位：独立用户（0：非独立用户；1：独立用户）
            * 第三位：是否可以删除（0：可以删除；1：不可删除）
            * 四到六位：1-5的权限等级
            */
            let grade = 0;
            grade += (submitData.canEditCanConfig << 5);
            grade += (submitData.self << 4);
            grade += (submitData.delFlg << 3);
            grade += (staffCode.length);

            submitData.grade = grade;

            delete submitData.delFlag;
            delete submitData.self;

            if ($utils.checkObjectIsNotEmpty(submitData.status)) {
              submitData.status = String(submitData.status);
            }
            if ($utils.checkObjectIsNotEmpty(submitData.isAdmin)) {
              submitData.isAdmin = String(submitData.isAdmin);
            }


            const method = this.isAdd ? "add" : "edit";

            const res = await userApi[method](submitData);

            this.$message.success(`帐号${message}成功`);

            if (this.isAdd) {
              this.$router.push({
                path: `/user/detail/${res}`
              });
            } else {
              submitData.password = "";
              this.form = submitData;
              this.onClose();
            }
          } catch (e) {
            this.$message.error(`帐号${message}失败`);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    onSelectCompany (company) {
      this.company = company;
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-pwd-box {
  >div {
    display: inline-block;
  }
  >div:first-child {
    width: calc(100% - 120px);
  }
  >div:last-child {
    width: 120px;
    text-align: right;
  }
}
</style>